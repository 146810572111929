import docReady from '@/js/helpers/doc-ready'
import { createCookie, readCookie } from '@/js/helpers/cookie'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { getParameterByName, updateUrlParameter } from '@/js/helpers/url'

const axios = require('axios')
let isLoading = false

gsap.registerPlugin(ScrollTrigger)

function randomInteger (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getSpotlight = (params) => {
  axios
    .get(window.ajaxurl, {
      params
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        const openedSpotlights = readCookie('opened-spotlights')
        if (openedSpotlights && !openedSpotlights.includes(params.postId)) {
          const cookieArr = JSON.parse(openedSpotlights)
          cookieArr.push(params.postId)
          createCookie('opened-spotlights', JSON.stringify(cookieArr))
        } else {
          const cookieArr = []
          cookieArr.push(params.postId)
          createCookie('opened-spotlights', JSON.stringify(cookieArr))
        }

        const ajaxContainer = document.getElementById('spotlight-container')

        ajaxContainer.insertAdjacentHTML('beforeEnd', data)

        const tl = gsap.timeline()

        if (params.bg) {
          tl.set(ajaxContainer, {
            background: params.bg
          })
        } else {
          console.log({ params })
        }

        tl.set(document.body, {
          overflow: 'hidden'
        })

        tl.to(ajaxContainer, {
          duration: 0.6,
          ease: 'expo.out',
          visibility: 'visible',
          opacity: '1',
          y: 0
        })

        const closeButton = ajaxContainer.querySelector('[data-close]')
        if (closeButton) {
          const closeHandler = e => {
            e.preventDefault()
            const buttonTl = gsap.timeline()
            buttonTl.to(ajaxContainer, {
              duration: 0.6,
              ease: 'expo.inOut',
              opacity: '0',
              y: '-50px'
            })

            buttonTl.set(ajaxContainer, {
              visibility: 'hidden'
            })

            buttonTl.set(document.body, {
              clearProps: 'overflow'
            })

            // empty div
            while (ajaxContainer.firstChild) {
              ajaxContainer.removeChild(ajaxContainer.firstChild)
            }

            window.history.replaceState({}, '', window.location.origin + window.location.pathname)

            closeButton.removeEventListener('click', closeHandler)
          }
          closeButton.addEventListener('click', closeHandler)
        }

        // checkImages()
        const url = updateUrlParameter(window.location.href, 'spotlight', params.postId)
        window.history.pushState({}, '', url)

        isLoading = false
      }
    })
}

class Spotlight {
  constructor (el, containerHeight, containerWidth) {
    this.el = el
    this.wrap = this.el.querySelector('.spotlight__wrap')
    this.block = this.el.querySelector('.spotlight__block')
    this.containerHeight = containerHeight
    this.containerWidth = containerWidth

    this.initVars()
    this.setPosition()
    this.scrollPosition()

    this.initEvents()
  }

  initVars () {
    const rand = Math.round(Math.random()) === 1

    this.startY = randomInteger(0, this.containerHeight)
    this.endY = randomInteger(this.startY - 50, this.startY + 50)
    this.startWidth = +this.el.getAttribute('data-start-width')
    this.endWidth = +this.el.getAttribute('data-end-width')
    this.startX = rand ? this.containerWidth + this.startWidth : 0 - this.startWidth
    this.endX = rand ? 0 - this.endWidth : this.containerWidth + this.endWidth
  }

  setPosition () {
    gsap.set(this.el, {
      top: this.startY
    })

    gsap.to(this.el, {
      opacity: 1,
      duration: 0.6,
      ease: 'expo.out'
    })
  }

  scrollPosition () {
    const blockStartY = randomInteger(-50, 50)
    const blockEndY = randomInteger(-50, 50)

    gsap.fromTo(this.wrap, {
      x: this.startX,
      y: blockStartY
    }, {
      x: this.endX,
      y: blockEndY,
      scrollTrigger: {
        trigger: this.el,
        scrub: 1,
        start: 'top bottom+=35%',
        end: 'bottom top-=35%'
      }
    })

    gsap.fromTo(this.block, {
      scaleX: 1
    }, {
      scaleX: this.endWidth / this.startWidth,
      scrollTrigger: {
        trigger: this.el,
        scrub: 1,
        start: 'top bottom+=35%',
        end: 'bottom top-=35%'
      }
    })
  }

  initEvents () {
    this.wrap.addEventListener('click', e => {
      e.preventDefault()

      if (isLoading) {
        return
      }

      isLoading = true

      const postId = this.wrap.getAttribute('data-spotlight-id')
      const bg = this.wrap.getAttribute('data-bg')

      getSpotlight({
        action: 'load_spotlight',
        postId,
        bg
      })
    })
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('.spotlight')).forEach(el => {
    // eslint-disable-next-line
    const spotlight = new Spotlight(el, document.documentElement.scrollHeight, window.innerWidth)
  })

  const paramPostId = getParameterByName('spotlight')
  if (paramPostId) {
    isLoading = true

    getSpotlight({
      action: 'load_spotlight',
      postId: paramPostId
    })
  }

  window.addEventListener('popstate', function () {
    isLoading = true
    const paramPostId = getParameterByName('spotlight')
    if (paramPostId) {
      getSpotlight({
        action: 'load_spotlight',
        postId: paramPostId
      })
    } else {
      isLoading = false
      const buttonTl = gsap.timeline()
      const ajaxContainer = document.getElementById('spotlight-container')

      if (ajaxContainer && ajaxContainer.innerHTML !== '') {
        buttonTl.to(ajaxContainer, {
          duration: 0.6,
          ease: 'expo.inOut',
          opacity: '0',
          y: '-50px'
        })

        buttonTl.set(ajaxContainer, {
          visibility: 'hidden'
        })

        buttonTl.set(document.body, {
          clearProps: 'overflow'
        })

        // empty div
        while (ajaxContainer.firstChild) {
          ajaxContainer.removeChild(ajaxContainer.firstChild)
        }

        window.history.pushState({}, '', window.location.origin + window.location.pathname)
      }
    }
  })
})
