import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

const lazyVideo = () => {
  const lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'))

  if ('IntersectionObserver' in window) {
    // eslint-disable-next-line
    const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (const source in video.target.children) {
            const videoSource = video.target.children[source]
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove('lazy')
          gsap.to(video.target, {
            duration: 1,
            opacity: '1',
            ease: 'power3.out',
            delay: 1
          })
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo)
    })
  }
}

docReady(() => {
  lazyVideo()
})
