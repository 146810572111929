import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const form = document.querySelector('.searchbar__form')

  if (form) {
    const yearSelect = document.querySelector('.searchbar__year')
    if (yearSelect) {
      yearSelect.addEventListener('change', () => {
        form.submit()
      })
    }

    Array.from(document.querySelectorAll('.searchbar-cat__input')).forEach(el => {
      el.addEventListener('change', () => {
        console.log('change')
        form.submit()
      })
    })
  }
})
