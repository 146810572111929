import throttle from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'

class Navigation {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-hamburger')
    this.wrapper = this.body.querySelector('.wrapper')
    this.menus = this.body.querySelectorAll('.nav')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.slide = this.body.querySelector('.nav-slide')
    this.active = false

    this.initEvents()
  }

  menuSlideIn () {
    for (let i = 0; i < this.hamburgers.length; i++) {
      this.hamburgers[i].classList.remove('nav-hamburger--is-active')
    }
    this.wrapper.classList.add('wrapper--slide')
  }

  toggleMenu () {
    this.active = !this.active
    // And toggle the active class
    this.body.classList.toggle('body--slide-active', this.active)

    this.slide.setAttribute('aria-hidden', this.active ? 'false' : 'true')

    if (this.active) {
      this.slide.focus()
    }

    Array.from(this.menus).forEach(menu => {
      menu.classList.toggle('nav--active', this.active)
    })

    Array.from(this.hamburgers).forEach(hamburger => {
      hamburger.classList.toggle('nav-hamburger--is-active', this.active)
    })
  }

  headerScroll (ws) {
    const isScrolled = ws > 30
    this.body.classList.toggle('menu-scroll', isScrolled)
    Array.from(this.menus).forEach(menu => {
      menu.classList.toggle('menu-scroll', isScrolled)
    })
  }

  initEvents () {
    const clickHandler = () => this.toggleMenu()

    Array.from(this.toggleElements).forEach(el => {
      el.addEventListener('click', clickHandler)
    })

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll(window.pageYOffset)
  }
}

docReady(() => {
  // eslint-disable-next-line
  const nav = new Navigation()
})
