import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import createSlider from '@/js/helpers/create-slider'

class GallerySLider {
  constructor (el) {
    this.el = el
    this.triggerImages = document.querySelectorAll('[data-open-gallery]')
    this.galleryClose = this.el.querySelector('[data-close]')
    this.initEvents()
  }

  openGallery (imageId) {
    const tl = gsap.timeline()
    // Remove overflow on body
    tl.set(document.body, {
      overflow: 'hidden'
    })

    // Set gallery to display
    tl.set(this.el, {
      display: 'block',
      opacity: 0,
      onComplete: () => {
        // Loop over cells
        this.flkty.cells.forEach((cell, index) => {
          // Select the current active cell
          if (cell.element && cell.element.getAttribute('data-slide-id') === imageId) {
            this.flkty.selectCell(index, true, true)
          }
        })

        // Resize to 'init'
        this.flkty.resize()
      }
    })

    // Show gallery
    tl.to(this.el, {
      duration: 0.6,
      ease: 'expo.out',
      opacity: 1
    })
  }

  closeGallery () {
    const tl = gsap.timeline()

    tl.to(this.el, {
      duration: 0.3,
      ease: 'expo.inOut',
      opacity: 0
    })

    tl.set(this.el, {
      clearProps: 'all'
    })

    tl.set(document.body, {
      clearProps: 'overflow'
    })
  }

  initEvents () {
    Array.from(this.triggerImages).forEach(el => {
      const slideId = el.getAttribute('data-open-gallery')
      el.addEventListener('click', this.openGallery.bind(this, slideId))
    })

    this.galleryClose.addEventListener('click', this.closeGallery.bind(this))

    this.flkty = createSlider(this.el, {
      percentPosition: true,
      autoPlay: false,
      pageDots: false,
      draggable: true,
      accessibility: true,
      prevNextButtons: false,
      wrapAround: true,
      contain: true,
      cellAlign: 'left',
      groupCells: false
    })
  }
}

docReady(() => {
  const gallerySliderEl = document.querySelector('.gallery-slider')
  if (gallerySliderEl) {
    // eslint-disable-next-line
    const gallerySlider = new GallerySLider(gallerySliderEl)
  }
})
