import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.l-projects-slider__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      autoPlay: false,
      pageDots: false,
      draggable: true,
      accessibility: true,
      prevNextButtons: false
    })
  })

  Array.from(document.querySelectorAll('.application-menu')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      autoPlay: false,
      pageDots: false,
      draggable: true,
      accessibility: true,
      prevNextButtons: false,
      wrapAround: false,
      contain: true,
      cellAlign: 'left',
      initialIndex: '[data-slider-active]'
    })
  })

  Array.from(document.querySelectorAll('.l-featured-portraits__portraits')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      autoPlay: false,
      pageDots: false,
      draggable: true,
      accessibility: true,
      prevNextButtons: false,
      wrapAround: false,
      contain: true,
      cellAlign: 'left',
      groupCells: true
    })
  })
})
