import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

class AccordionItem {
  constructor (el) {
    // Elements
    this.el = el
    this.header = this.el.querySelector('[data-faq-header]')
    this.body = this.el.querySelector('[data-faq-body]')
    this.content = this.el.querySelector('[data-faq-content]')

    // Active
    this.active = false

    // Init
    this.initEvents()
  }

  clickHandler () {
    this.active = !this.active
    this.el.classList.toggle('active', this.active)

    if (this.header.hasAttribute('aria-label')) {
      this.header.setAttribute('aria-label', this.active ? 'Item sluiten' : 'Item openen')
    }

    gsap.to(this.body, {
      duration: this.active ? 1 : 0.4,
      ease: 'expo.out',
      height: this.active ? this.content.offsetHeight : 0
    })
    gsap.to(this.content, {
      duration: this.active ? 0.6 : 0.4,
      delay: this.active ? 0.1 : 0,
      ease: 'expo.out',
      y: this.active ? 0 : 50,
      opacity: this.active ? 1 : 0
    })
  }

  initEvents () {
    if (this.body) {
      gsap.set(this.body, {
        height: 0
      })
      gsap.set(this.content, {
        y: 50,
        opacity: 0
      })
    }

    this.header.addEventListener('click', this.clickHandler.bind(this))
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-faq-item]')).forEach(el => {
    // eslint-disable-next-line
    const accordionItem = new AccordionItem(el)
  })
})
