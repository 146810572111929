// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/navigation'
import '@/js/components/sliders'
import '@/js/components/map'
import '@/js/components/accordion'
import '@/js/components/spotlights'
import '@/js/components/gallery'
import '@/js/components/search'
import '@/js/components/video'
import '@/js/components/team'
import '@/js/components/form-popup'
// import '@/js/components/fonts'
